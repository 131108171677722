// MAIN.JS
jQuery(function($) {

	/**********************************************/
	$layerslider = $('#layerslider');
	$layerslidermobile = $('#layerslider-mobile');

$layerslider.layerSlider({
	responsive: true,
	pauseOnHover: false,
	navPrevNext: false,
	navStartStop: false,
	navButtons: false,
	hoverPrevNext: false,
	keybNav: false,
	showCircleTimer: false,
	thumbnailNavigation: 'disabled',
	skinsPath: 'scss/addons/layerslider/skins/'
});

$layerslidermobile.layerSlider({
	responsive: true,
	navButtons: false,
	pauseOnHover: false,
	navPrevNext: false,
	navStartStop: false,
	hoverPrevNext: false,
	keybNav: false,
	showCircleTimer: false,
	thumbnailNavigation: 'disabled',
	skinsPath: 'scss/addons/layerslider/skins/'
});

/*******************************************************/
if ($.fn.booklet) {
		var $bttn_next		= $('#next_page_button');
		var $bttn_prev		= $('#prev_page_button');
    //single book
		if ($(window).width() >= 1100){

    $('#mybook').booklet({
								name:               null,                            // name of the booklet to display in the document title bar
								width:              900,                             // container width
								height:             600,                             // container height
								speed:              600,                             // speed of the transition between pages
								direction:          'LTR',                           // direction of the overall content organization, default LTR, left to right, can be RTL for languages which read right to left
								startingPage:       0,                               // index of the first page to be displayed
							//	easing:             'easeInOutQuad',                 // easing method for complete transition
							//	easeIn:             'easeInQuad',                    // easing method for first half of transition
							//	easeOut:            'easeOutQuad',                   // easing method for second half of transition

								closed:             false,                           // start with the book "closed", will add empty pages to beginning and end of book
								closedFrontTitle:   null,                            // used with "closed", "menu" and "pageSelector", determines title of blank starting page
								closedFrontChapter: null,                            // used with "closed", "menu" and "chapterSelector", determines chapter name of blank starting page
								closedBackTitle:    null,                            // used with "closed", "menu" and "pageSelector", determines chapter name of blank ending page
								closedBackChapter:  null,                            // used with "closed", "menu" and "chapterSelector", determines chapter name of blank ending page
								covers:             false,                           // used with  "closed", makes first and last pages into covers, without page numbers (if enabled)

								pagePadding:        10,                              // padding for each page wrapper
								pageNumbers:        true,                            // display page numbers on each page

								hovers:             false,                            // enables preview pageturn hover animation, shows a small preview of previous or next page on hover
								overlays:           false,                            // enables navigation using a page sized overlay, when enabled links inside the content will not be clickable
								tabs:               false,                           // adds tabs along the top of the pages
								tabWidth:           60,                              // set the width of the tabs
								tabHeight:          20,                              // set the height of the tabs
								arrows:             false,                           // adds arrows overlayed over the book edges
								//cursor:             'pointer',                       // cursor css setting for side bar areas

								hash:               false,                           // enables navigation using a hash string, ex: #/page/1 for page 1, will affect all booklets with 'hash' enabled
								keyboard:           true,                            // enables navigation with arrow keys (left: previous, right: next)
								next:               $bttn_next,          			// selector for element to use as click trigger for next page
								prev:               $bttn_prev,          			// selector for element to use as click trigger for previous page

								menu:               null,                            // selector for element to use as the menu area, required for 'pageSelector'
								pageSelector:       false,                           // enables navigation with a dropdown menu of pages, requires 'menu'
								chapterSelector:    false,                           // enables navigation with a dropdown menu of chapters, determined by the "rel" attribute, requires 'menu'

								//shadows:            true,                            // display shadows on page animations
								//shadowTopFwdWidth:  166,                             // shadow width for top forward anim
								//shadowTopBackWidth: 166,                             // shadow width for top back anim
								//shadowBtmWidth:     50,                              // shadow width for bottom shadow

								before:             function(){},                    // callback invoked before each page turn animation
								after:              function(){}
		});
	}
	if ($(window).width() < 1100 && $(window).width() >= 767){
		$('#mybook').booklet({
								name:               null,                            // name of the booklet to display in the document title bar
								width:              700,                             // container width
								height:             600,                             // container height
								speed:              600,                             // speed of the transition between pages
								direction:          'LTR'
							});
	}
	else {
			$('.owl-carousel').owlCarousel({
			loop:false,
			nav:true,
			autoplay:false,
			autoplayTimeout:3000,
			autoplayHoverPause:true,
			navText: ["<img src='images/book/button_left.png'>","<img src='images/book/button_right.png'>"],
			responsive:{
					0:{items:1},
			}
		})
	}

}

/*******************************************************************/
	// JQUERY.APPEAR
	if ($.fn.appear) {
		var appearSelectors = ".appear";
		$(appearSelectors).on('appear', function(event, $all_appeared_elements) {
			$.each($all_appeared_elements, function(e) {
				$(this).css("opacity", 1);
			});
		});
		$(appearSelectors).on('disappear', function(event, $all_disappeared_elements) {
			$.each($all_disappeared_elements, function(e) {
				// Activer ceci pour faire l'inverse lors que l'element sort de la partie visible
				// $(this).css("opacity", 0);
			});
		});
		$(window).load(function() {
			$(appearSelectors).appear({"force_process":true});
		});
	}

	/**********************************************/

	// JQUERY.MASKEDINPUT
	if ($.fn.mask) {
		$("input[type='text'].phone").mask("(999) 999-9999");
		$("input[type='text'].postalcode").mask("a9a9a9");
	}

	/**********************************************/

	// JQUERY.MAGNIFIC-POPUP
	if ($.fn.magnificPopup) {
		// image
		//$('.fade11').magnificPopup({type:'image'});
		$('.image-popup').magnificPopup({type:'image'});
		$('.image-group-popup').magnificPopup({
			delegate: 'a',
			type: 'image'
		});

		// iframe
		$('.iframe-popup').magnificPopup({type:'iframe'});
		$('.iframe-group-popup').magnificPopup({
			delegate: 'a',
			type: 'iframe'
		});
	}

	/**********************************************/

	// MENU
	var menu = "#mobile-main-menu";
	var menuToggle = ".menu-toggle";
	$(menuToggle).click(function(e) {
		$(menu).toggleClass("toggled");
		$(menuToggle).toggleClass("toggled");
	});

	/**********************************************/

	// JQUERY.MATCHHEIGHT
	if ($.fn.matchHeight) {
		$(".matchHeight").matchHeight();
	}

	/**********************************************/

	// OWL.CAROUSEL2
	if ($.fn.owlCarousel) {
		// Etre plus specific dans la classe des carousels dans le cas ou nous avons plusieur instance donc les settings differe!
		$('.owl-carousel').owlCarousel();
	}

	/**********************************************/

	// PARALLAX
	if ($.fn.parallax) {
		$(".parallax-slow").parallax({
			speed: 0.1
		});
		$(".parallax").parallax({
			speed: 0.3
		});
		$(".parallax-fast").parallax({
			speed: 0.5
		});
	}

/**************************************************************/
$('#btnDel').attr('disabled',true);
  $('#btnAdd').click(function() {
    var num = $('.clonedInput').length; // how many "duplicatable" input fields we currently have


		var newNum = num + 1; // the numeric ID of the new input field being added

    // create the new element via clone(), and manipulate it's ID using newNum value
    var newElem = $('#input1').clone().attr('id', 'input' + newNum);
		//alert(newElem.children(1));
    // manipulate the name/id values of the input inside the new element

		//alert(newElem.children([0]));
		var valueEmpty = '';
		//newElem.children('#Produit').attr('id', 'Produit Commande' + newNum).attr('name', 'Produit Commande' + newNum);
		newElem.find('#Transport-bitume').attr('id', 'Transport-bitume Commande' + newNum).attr('name', 'TRANSPORT Commande' + newNum).attr('for', 'Transport-bitume Commande' + newNum).prop('checked', false);
		newElem.find('#Transport-émulsion').attr('id', 'Transport-émulsion Commande' + newNum).attr('name', 'TRANSPORT Commande' + newNum).attr('for', 'Transport-émulsion Commande' + newNum).prop('checked', false);

		newElem.find('#Produit').attr('id', 'Produit Commande' + newNum).attr('name', 'PRODUIT Commande' + newNum).val(valueEmpty);
		newElem.find('#Qté').attr('id', 'Qté Commande' + newNum).attr('name', 'QTÉ Commande' + newNum).val(valueEmpty);
		newElem.find('#Lieu-chargement').attr('id', 'Lieu de chargement Commande' + newNum).attr('name', 'LIEU DE CHARGEMENT Commande' + newNum).val(valueEmpty);
		newElem.find('#Autre-Lieu-chargement').attr('id', 'Autre-Lieu-chargement Commande' + newNum).attr('name', 'AUTRE LIEU DE CHARGEMENT Commande' + newNum).val(valueEmpty);
		newElem.find('#Lieu-livraison').attr('id', 'Lieu de livraison Commande' + newNum).attr('name', 'LIEU DE LIVRAISON Commande' + newNum).val(valueEmpty);
		newElem.find('#date').attr('id', 'date Commande' + newNum).attr('name', 'DATE Commande' + newNum).val(valueEmpty);
		newElem.find('#Heure').attr('id', 'Heure Commande' + newNum).attr('name', 'HEURE Commande' + newNum).val(valueEmpty);
		newElem.find('#Commande-transport').attr('id', 'NumCommande transport Commande' + newNum).attr('name', 'NUM COMMANDE TRANSPORT Commande' + newNum).val(valueEmpty);
		newElem.find('#Commande-produit').attr('id', 'NumCommande produit Commande' + newNum).attr('name', 'NUM COMMANDE PRODUIT Commande' + newNum).val(valueEmpty);
		newElem.find('#Confirmation').attr('id', 'Confirmation Commande' + newNum).attr('name', 'CONFIRMATION COMMANDE' + newNum).attr('for', 'Confirmation Commande' + newNum).val(valueEmpty);



		// insert the new element after the last "duplicatable" input field
    $('#input' + num).after(newElem);

    // enable the "remove" button
    $('#btnDel').css('display','block');

    // business rule: you can only add 10 names
    if (newNum == 100)
      $('#btnAdd').attr('disabled','disabled');
  });

  $('#btnDel').click(function() {
    var num = $('.clonedInput').length; // how many "duplicatable" input fields we currently have

		$('#input' + num).remove(); // remove the last element

    // enable the "add" button
    $('#btnAdd').attr('disabled',false);

    // if only one element remains, disable the "remove" button
    if (num-1 == 1)
		$('#btnDel').css('display','none');
      //$('#btnDel').attr('disabled','disabled');
  });
	//alert($('.clonedInput').length);


	/////*on select change montrer le input ou non du autre lieu de charment*/

	/*$('#Lieu-chargement').change(function(){
		var valor= $('#Lieu-chargement').val();
		if (valor == "Autre"){
			$('.autre').css('display','block');
		}
		else {
			$('.autre').css('display','none');
		}
	})

 //alert($('[id^=Lieu de chargement Commande]').length);
	for (var i = 1; i <= $('.clonedInput').length; i++) {
		//var pos = (i+1);
		//alert(pos);
		alert(i);
		if (i >= 2) {

			$('#Lieu de chargement Commande'+ i).change(function(){
				var valor= $('#Lieu de chargement Commande'+ i).val();
				if (valor == "Autre"){
					$('.autre').css('display','block');
				}
				else {
					$('.autre').css('display','none');
				}
			})
		}
	}*/
	/////* end on select change montrer le input ou non du autre lieu de charment*/

});
